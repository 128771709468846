<template>
  <b-container fluid>
    <div class="d-flex align-items-center">
      <div class="my-1 mr-2">
        <b-form-group label="Date From">
          <flat-pickr
            v-model="dateFrom"
            :config="{
              dateFormat: 'Y-m-d',
              maxDate: dateTo,
            }"
            class="form-control font-small-2 w-auto"
            placeholder="Choose Date"
            readonly="readonly"
            @input="getOtpLogs()"
          />
        </b-form-group>
      </div>
      <div class="my-1">
        <b-form-group label="Date To">
          <flat-pickr
            v-model="dateTo"
            :config="{
              dateFormat: 'Y-m-d',
              minDate: dateFrom,
            }"
            class="form-control font-small-2 w-auto"
            placeholder="Choose Date"
            readonly="readonly"
            @input="getOtpLogs()"
          />
        </b-form-group>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-card>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    MyTable,
    flatPickr,
  },
  async created() {
    this.getOtpLogs();
  },
  methods: {
    getOtpLogs() {
      this.isLoading = true;
      this.$http
        .get(`driver_otp/${this.dateFrom}/${this.dateTo}`)
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows = [...response.data.data];
          } else {
            this.rows = [];
          }
          this.isLoading = false;
        });
    },
  },
  data() {
    return {
      dateTo: new Date().toISOString().split("T")[0],
      dateFrom: new Date(Date.now() - 172800000).toISOString().split("T")[0],
      columns: [
        {
          label: "ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "User ID",
          field: "user_id",
          tdClass: "text-center align-middle",
        },
        {
          label: "User Name",
          field: "driver_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Date Added",
          field: "date_added",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "OTP Token",
          field: "otp",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
